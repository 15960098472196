import { FilterParams } from '@orascom/api-interfaces';
import { parseError } from '@orascom/utils';
import { Destination as DestinationApi } from '../api/destination';
import { CountPerUnitTypeBreakdown } from '../definitions/interfaces/destinations';
import { SalesRevenuePerUnitType } from '../definitions/analytics';

export class Destination {
  public static getAnalyticsDestinationSalesPerformance(
    params: FilterParams
  ): Promise<CountPerUnitTypeBreakdown> {
    return DestinationApi.getAnalyticsDestinationSalesPerformance(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsSalesRevenuePerUnitType(
    params: FilterParams
  ): Promise<SalesRevenuePerUnitType> {
    return DestinationApi.getAnalyticsSalesRevenuePerUnitType(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}

export const lineColors = [
  '#003A70',
  '#DFBD69',
  '#FF7B00',
  '#C60000',
  '#547b9f',
  '#8e753e',
  '#f08686',
  '#a1d7bb',
  '#32a96c',
  '#1c5d3b',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
