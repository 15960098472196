import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Engagement as EngagementUtils } from '../../utils/engagement';
import { errorMessagesHandler } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';
import styles from './customer-demographics.module.scss';
import { PageViewsPerCountry } from '../../definitions/interfaces/engagmenet';
import { GraphProps } from '../../definitions/analytics';
import PieChartCard from '../pie-chart-card/pie-chart-card';

export function CustomerDemographics(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [viewsBreakdownByCountry, setViewsBreakdownByCountry] =
    useState<PageViewsPerCountry[]>();
  const [loading, setLoading] = useState(false);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    EngagementUtils.getAnalyticsPageViewsPerCountry(params)
      .then((res) => {
        setViewsBreakdownByCountry(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  const chartColors = ['#003A70', '#D9E2EA', '#4C759B', '#021E37', '#B7D7EA'];

  const viewBreakdownChartData = viewsBreakdownByCountry?.map(
    (breakdown, index) => ({
      title: breakdown.name ?? 'unknown method',
      value: breakdown.views_count,
      key: breakdown.percentage,
      color: chartColors[index],
    })
  );

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">
        {t('customerDemographicsDescription')}
      </h3>
      <h2 className="shoppes-analytics__title">{t('customerDemographics')}</h2>
      <div className={styles['content']}>
        {viewBreakdownChartData?.length ? (
          <PieChartCard
            cardTitle={t('viewsBreakdownPerCountry')}
            chartData={viewBreakdownChartData}
          />
        ) : (
          <p>No views</p>
        )}
      </div>
    </div>
  );
}

export default CustomerDemographics;
