import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UnitsServicesRequestsByType from '../../components/units-services-requests-by-type/units-services-requests-by-type';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import useClearSelections from '../../hooks/use-clear-filters-selections';

export function Services() {
  const { t } = useTranslation();

  const {
    filterDestinations,
    filterUnitTypes,
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    unitFilterLoader,
    destinationFilterLoader,
  } = useContext(FILTER_CONTEXT);

  const { selectedDuration } = useAnalyticsPeriodFilter();

  const { startDateArg, endDateArg } = useAnalyticsFilters();

  useClearSelections({
    handleSelectedDestinations,
    handleSelectedUnitTypes,
  });

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>{t('services')}</h1>
      </div>

      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
          destinationsLoader={destinationFilterLoader}
          unitsLoader={unitFilterLoader}
        />

        <UnitsServicesRequestsByType
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
      </div>
    </div>
  );
}

export default Services;
