import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import { LeadsPerformance } from '../../components/leads-performance/leads-performance';
import LeadsPerDestination from '../../components/leads-per-destination/leads-per-destination';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import SalesVsLeads from '../../components/sales-vs-leads/sales-vs-leads';
import DirectVsIndirect from '../../components/direct-vs-indirect/direct-vs-indirect';
import useClearSelections from '../../hooks/use-clear-filters-selections';

export function LeadInsights() {
  const { t } = useTranslation();
  const {
    filterDestinations,
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    destinationFilterLoader,
    handleSelectedUnitTypes,
  } = useContext(FILTER_CONTEXT);

  const { selectedDuration } = useAnalyticsPeriodFilter();

  useClearSelections({
    handleSelectedDestinations,
    handleSelectedUnitTypes,
  });

  const { startDateArg, endDateArg } = useAnalyticsFilters();
  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {t('performance')}
          <b>
            {' / '} {t('leadInsights')}
          </b>
        </h1>
      </div>

      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          handleSelectedDestinations={handleSelectedDestinations}
          portal="shopper-analytics"
          destinationsLoader={destinationFilterLoader}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
        />
        <LeadsPerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedDuration={selectedDuration}
        />
        <LeadsPerDestination
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedDuration={selectedDuration}
        />
        <SalesVsLeads
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedDuration={selectedDuration}
        />
        <DirectVsIndirect
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedDuration={selectedDuration}
        />
      </div>
    </div>
  );
}

export default LeadInsights;
