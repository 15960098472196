import { FilterParams, Payload } from '@orascom/api-interfaces';
import { constructURL } from '@orascom/utils';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import { UnitServicesRequestsByType } from '../definitions/interfaces/services';

export class Services {
  public static getUnitServicesRequestsByType(
    params: FilterParams
  ): Promise<Payload<UnitServicesRequestsByType[]>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getUnitServicesRequestsByType.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getUnitServicesRequestsByType.method,
    });
  }
}
