import { FilterParams, Payload } from '@orascom/api-interfaces';
import { constructURL } from '@orascom/utils';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import { CountPerUnitTypeBreakdown } from '../definitions/interfaces/destinations';
import { SalesRevenuePerUnitType } from '../definitions/analytics';

export class Destination {
  public static getAnalyticsDestinationSalesPerformance(
    params: FilterParams
  ): Promise<Payload<CountPerUnitTypeBreakdown>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsDestinationSalesPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsDestinationSalesPerformance.method,
    });
  }

  public static getAnalyticsSalesRevenuePerUnitType(
    params: FilterParams
  ): Promise<Payload<SalesRevenuePerUnitType>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesRevenuePerUnitType.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesRevenuePerUnitType.method,
    });
  }
}
