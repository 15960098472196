import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { GraphProps, PerformanceGraphs } from '../../definitions/analytics';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export function UnitTypePerformance(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [unitPerformance, setUnitPerformance] = useState<PerformanceGraphs>();
  const activeTabs = ['Sales Revenue'];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };

    AnalyticsUtils.getAnalyticsUnitPerformance(params)
      .then((res) => {
        setUnitPerformance(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  const data = {
    labels: unitPerformance?.sales_revenue?.map((unit) => unit.name),
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data:
          unitPerformance?.sales_revenue?.map((unit) => ({
            x: unit.name,
            y: Number(unit.sales_revenues),
            currency: unit.currency,
          })) || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      description={t('unitTypePerformanceDescription')}
      title={t('unitTypePerformance')}
      data={data}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default UnitTypePerformance;
