/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import {
  DestinationViews,
  GraphData,
  GraphProps,
  PerformanceGraphs,
} from '../../definitions/analytics';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export function DestinationPerformance(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [viewsLoading, setViewsLoading] = useState(true);
  const activeTabs = ['Sales Revenue', 'Requests', 'Views'];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [destinationPerformance, setDestinationPerformance] =
    useState<PerformanceGraphs>();
  const [destinationViews, setDestinationViews] = useState<DestinationViews>();
  const [activeTabLabels, setActiveTabLabels] = useState<string[]>([]);
  const [activeTabData, setActiveTabData] = useState<GraphData[]>([]);

  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  const processTabData = (tab: string, destPerformance: PerformanceGraphs) => {
    let labels: string[] = [];
    let data: GraphData[] = [];

    switch (tab) {
      case 'Sales Revenue':
        labels = destPerformance?.sales_revenue?.map(
          (destination) => destination.name
        );
        data = destPerformance?.sales_revenue?.map((destination) => ({
          x: destination.name,
          y: Number(destination.sales_revenues),
          currency: destination.currency,
        }));
        break;
      case 'Requests':
        labels =
          destPerformance?.requests?.map((destination) => destination.name) ??
          [];
        data =
          destPerformance?.requests?.map((destination) => ({
            x: destination.name,
            y: Number(destination.requests_count),
          })) ?? [];
        break;
      case 'Views':
        labels =
          destinationViews?.views?.map((destination) => destination.name) ?? [];
        data =
          destinationViews?.views?.map((destination) => ({
            x: destination.name,
            y: Number(destination.views_count),
          })) ?? [];
        break;
      default:
        break;
    }

    return { labels, data };
  };

  useEffect(() => {
    if (activeTab && destinationPerformance) {
      const { labels, data } = processTabData(
        activeTab,
        destinationPerformance
      );
      setActiveTabLabels(labels);
      setActiveTabData(data);
    }
  }, [activeTab, destinationPerformance]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    AnalyticsUtils.getAnalyticsDestinationPerformance(params)
      .then((res) => {
        setDestinationPerformance(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    AnalyticsUtils.getAnalyticsDestinationViews(params)
      .then((res) => {
        setDestinationViews(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setViewsLoading(false);
      });
  }, [startTimeStamp, endTimeStamp]);

  const data = {
    labels: activeTabLabels,
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data: activeTabData || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  const requestsDimmed =
    activeTab === 'Requests' && (props.selectedUnitTypesIds?.length || 0) > 0;

  const viewsDimmed =
    activeTab === 'Views' &&
    ((props.selectedDestinationsIds?.length || 0) > 0 ||
      (props.selectedUnitTypesIds?.length || 0) > 0);

  if (loading || viewsLoading) {
    return <Loader />;
  }
  return (
    <BarChart
      dim={requestsDimmed || viewsDimmed}
      description={t('destinationPerformanceDescription')}
      title={t('destinationPerformance')}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default DestinationPerformance;
