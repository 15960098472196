import { CURRENCIES_ENUM } from '@orascom/api-interfaces';

export interface SalesPercentagePerPaymentMethod {
  payment_method: string;
  sales_count: number;
  sales_count_percent: number;
}

export interface PaymentBreakdown {
  sales_percentage_per_payment_method: SalesPercentagePerPaymentMethod[];
}

export interface SalesPercentagePerCurrency {
  currency: CURRENCIES_ENUM;
  sales_count: number;
  sales_count_percent: number;
}

export interface CurrencyBreakdown {
  sales_percentage_per_currency: SalesPercentagePerCurrency[];
}

export enum RequestTypeEnum {
  LEAD = 'lead',
  UNIT_SERVICE = 'unit_service',
  UNIT_RESALE = 'unit_resale',
  REFERRAL = 'referral',
}
