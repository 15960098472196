import { Loader } from '@orascom/common-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { DirectVsIndirectSales, GraphProps } from '../../definitions/analytics';
import { useSearchParams } from 'react-router-dom';

export function DirectVsIndirect(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const activeTabs = ['Destination', 'Unit Type'];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [purchasesData, setPurchasesData] = useState<DirectVsIndirectSales>();
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');
  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
    };
    PerformanceUtils.getAnalyticsDirectVsIndirectSales(params)
      .then((res) => {
        setPurchasesData(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedDestinations]);

  if (loading) {
    return <Loader />;
  }

  const data = {
    labels:
      activeTab === 'Destination'
        ? purchasesData?.destinations_purchases.map(
            (destination) => destination.name
          )
        : purchasesData?.unit_types_purchases.map((unit) => unit.name),
    datasets: [
      {
        label: t('directPurchase'),
        data:
          (activeTab === 'Destination'
            ? purchasesData?.destinations_purchases.map((destination) => ({
                x: destination.name,
                y: Number(destination.direct_sales_count),
              }))
            : purchasesData?.unit_types_purchases.map((unit) => ({
                x: unit.name,
                y: Number(unit.direct_sales_count),
              }))) || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
      {
        label: t('purchaseFromRequest'),
        data:
          (activeTab === 'Destination'
            ? purchasesData?.destinations_purchases.map((destination) => ({
                x: destination.name,
                y: Number(destination.indirect_sales_count),
              }))
            : purchasesData?.unit_types_purchases.map((unit) => ({
                x: unit.name,
                y: Number(unit.indirect_sales_count),
              }))) || [],
        backgroundColor: '#B4905C',
        hoverBackgroundColor: '##B4905C',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  return (
    <BarChart
      description={t('directVsIndirectDescription')}
      title={t('directPurchaseVsRequest')}
      data={data}
      unMaintainAspectRatio
      activeTab={activeTab}
      activeTabs={activeTabs}
      setActiveTab={setActiveTab}
    />
  );
}

export default DirectVsIndirect;
