import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import {
  AnalyticsPeriodFilter,
  LifecycleContent,
  LifecycleHeaders,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { SalesFunnelAnalysis } from '../../definitions/analytics';
import { errorMessagesHandler } from '@orascom/utils';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '@orascom/common-components';

export function FunnelAnalysis() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [salesFunnelAnalysis, setSalesFunnelAnalysis] =
    useState<SalesFunnelAnalysis>();

  const {
    filterDestinations,
    filterUnitTypes,
    handleSelectedDestinations,
    handleSelectedUnitTypes,
    unitFilterLoader,
    destinationFilterLoader,
  } = useContext(FILTER_CONTEXT);
  const [searchParams] = useSearchParams();
  const { startDateArg, endDateArg } = useAnalyticsFilters();

  useEffect(() => {
    const params = {
      from: startDateArg,
      to: endDateArg,
    };
    PerformanceUtils.getAnalyticsSalesFunnel(params)
      .then((res) => {
        setSalesFunnelAnalysis(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams.get('start'), searchParams.get('end')]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {t('performance')}
          <b>
            {' / '} {t('funnelAnalysis')}
          </b>
        </h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
          destinationsLoader={destinationFilterLoader}
          unitsLoader={unitFilterLoader}
        />
        {salesFunnelAnalysis ? (
          <>
            <div>
              <p className="shoppes-analytics__sub-title">
                {t('onlineSalesFunnelAnalysisDecsription')}
              </p>
              <h2 className="shoppes-analytics__title">
                {t('onlineSalesFunnelAnalysis')}
              </h2>
              <div className="card">
                <LifecycleHeaders />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.online_funnel.guest_count}
                  cycleContentTitle="Guest"
                  cycleContentCount={
                    salesFunnelAnalysis?.online_funnel.guest_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.online_funnel.guest_count
                  }
                />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.online_funnel.guest_count}
                  cycleContentTitle="Logged in"
                  cycleContentCount={
                    salesFunnelAnalysis?.online_funnel.logged_in_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.online_funnel.guest_count
                  }
                />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.online_funnel.guest_count}
                  cycleContentTitle="Online sales"
                  cycleContentCount={
                    salesFunnelAnalysis?.online_funnel.sales_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.online_funnel.logged_in_count
                  }
                />
              </div>
            </div>
            <div>
              <p className="shoppes-analytics__sub-title">
                {t('offlineSalesFunnelAnalysisDescription')}
              </p>
              <h2 className="shoppes-analytics__title">
                {t('offlineSalesFunnelAnalysis')}
              </h2>
              <div className="card">
                <LifecycleHeaders />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.offline_funnel.guest_count}
                  cycleContentTitle="Guest"
                  cycleContentCount={
                    salesFunnelAnalysis?.offline_funnel.guest_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.offline_funnel.guest_count
                  }
                />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.offline_funnel.guest_count}
                  cycleContentTitle="Lead Submissions"
                  cycleContentCount={
                    salesFunnelAnalysis?.offline_funnel.leads_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.offline_funnel.guest_count
                  }
                />
                <LifecycleContent
                  baseCount={salesFunnelAnalysis?.offline_funnel.guest_count}
                  cycleContentTitle="Offline Sales"
                  cycleContentCount={
                    salesFunnelAnalysis?.offline_funnel.sales_count
                  }
                  cycleContentPrevStepCount={
                    salesFunnelAnalysis?.offline_funnel.leads_count
                  }
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default FunnelAnalysis;
