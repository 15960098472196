export interface SummaryCardProps {
  cardIconSrc: string;
  cardTitle: string;
  cardDesc: string;
  dim?: boolean;
}

export function SummaryCard(props: SummaryCardProps) {
  return (
    <div
      className={`summary-card card ${props.dim ? 'summary-card--dim' : ''}`}
    >
      <div className="summary-card__image">
        <img src={props.cardIconSrc} alt="" />
      </div>

      <h3 className="summary-card__title">{props.cardTitle}</h3>
      <p className="summary-card__desc">{props.dim ? '-' : props.cardDesc}</p>
    </div>
  );
}

export default SummaryCard;
