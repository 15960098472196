import { Destination, Route } from '@orascom/api-interfaces';
import {
  SidebarComponent,
  SidebarMenuItem,
  USER_CONTEXT,
} from '@orascom/broker-sales-man-common-components';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../api/routes';
import DestinationsIcon from '../../assets/icons/destinations.svg?react';
import DestinationsActiveIcon from '../../assets/icons/destinations-active.svg?react';
import DashboardIcon from '../../assets/icons/dashboard.svg?react';
import DashboardActiveIcon from '../../assets/icons/dashboard-active.svg?react';
import AnalyticsIcon from '../../assets/icons/analytics.svg?react';
import AnalyticsActiveIcon from '../../assets/icons/analytics--active.svg?react';
import { Authentication as AuthenticationAPI } from '../../api/authentication';
import { toast } from 'react-toastify';
import EngagementIcon from '../../assets/icons/engagement.svg?react';
import EngagementActiveIcon from '../../assets/icons/engagement-active.svg?react';
import ServicesIcon from '../../assets/icons/services.svg?react';
import ServicesActiveIcon from '../../assets/icons/services-active.svg?react';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
export function Sidebar() {
  const [loggingOut, setLoggingOut] = useState(false);
  const location = useLocation();
  const [subMenuAnalyticsOpen, setSubMenuAnalyticsOpen] = useState(false);
  const [subMenuEngagementOpen, setSubMenuEngagementOpen] = useState(false);
  const [subMenuDestOpen, setSubMenuDestOpen] = useState(false);

  const [availableDestinations, setAvailableDestinations] =
    useState<Destination[]>();

  const navigate = useNavigate();
  const userContext = useContext(USER_CONTEXT);
  const { t } = useTranslation();

  const isActive = (routeName: Route, id?: string | number) =>
    location.pathname === routeName.path ||
    location.pathname === routeName.getPath?.(id);

  const logoutHandler = () => {
    setLoggingOut(true);
    AuthenticationAPI.logoutUser()
      .then((res) => {
        userContext?.setUser?.(null);
        toast.success(res.message);
        navigate(ROUTES['Login'].path);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setLoggingOut(false));
  };

  useEffect(() => {
    AnalyticsUtils.listDestinations()
      .then((res) => {
        setAvailableDestinations(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  return (
    <SidebarComponent
      subtitle={t('shoppersAndPredeliveryAnalytics')}
      loggingOut={loggingOut}
      logoutHandler={logoutHandler}
    >
      <SidebarMenuItem
        route={ROUTES['Overview']}
        icon={<DashboardIcon />}
        activeIcon={<DashboardActiveIcon />}
        isActive={isActive(ROUTES['Overview'])}
        label={t('overview')}
      />
      <SubMenu
        label={t('performance')}
        icon={
          isActive(ROUTES['SalesPerformance']) ||
          isActive(ROUTES['LeadInsights']) ||
          isActive(ROUTES['FunnelAnalysis']) ? (
            <AnalyticsActiveIcon />
          ) : (
            <AnalyticsIcon />
          )
        }
        active={
          isActive(ROUTES['SalesPerformance']) ||
          isActive(ROUTES['LeadInsights']) ||
          isActive(ROUTES['FunnelAnalysis'])
        }
        open={subMenuAnalyticsOpen}
        onOpenChange={() => setSubMenuAnalyticsOpen(!subMenuAnalyticsOpen)}
      >
        <MenuItem
          component={<Link to={ROUTES['SalesPerformance'].path} />}
          active={isActive(ROUTES['SalesPerformance'])}
        >
          {t('salesPerformance')}
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES['LeadInsights'].path} />}
          active={isActive(ROUTES['LeadInsights'])}
        >
          {t('leadInsights')}
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES['FunnelAnalysis'].path} />}
          active={isActive(ROUTES['FunnelAnalysis'])}
        >
          {t('funnelAnalysis')}
        </MenuItem>
      </SubMenu>

      <SubMenu
        label={t('engagement')}
        icon={
          isActive(ROUTES['CustomerBehvaiour']) ||
          isActive(ROUTES['Demographics']) ? (
            <EngagementActiveIcon />
          ) : (
            <EngagementIcon />
          )
        }
        active={
          isActive(ROUTES['CustomerBehvaiour']) ||
          isActive(ROUTES['Demographics'])
        }
        open={subMenuEngagementOpen}
        onOpenChange={() => setSubMenuEngagementOpen(!subMenuEngagementOpen)}
      >
        <MenuItem
          component={<Link to={ROUTES['CustomerBehvaiour'].path} />}
          active={isActive(ROUTES['CustomerBehvaiour'])}
        >
          {t('customerBehavior')}
        </MenuItem>
        <MenuItem
          component={<Link to={ROUTES['Demographics'].path} />}
          active={isActive(ROUTES['Demographics'])}
        >
          {t('demographics')}
        </MenuItem>
      </SubMenu>

      <SidebarMenuItem
        route={ROUTES['Services']}
        icon={<ServicesIcon />}
        activeIcon={<ServicesActiveIcon />}
        isActive={isActive(ROUTES['Services'])}
        label={t('services')}
      />
      <SubMenu
        label={t('destinations')}
        icon={
          location.pathname.includes('destination') ? (
            <DestinationsActiveIcon />
          ) : (
            <DestinationsIcon />
          )
        }
        active={location.pathname.includes('destination')}
        open={subMenuDestOpen}
        onOpenChange={() => setSubMenuDestOpen(!subMenuDestOpen)}
      >
        {availableDestinations?.map((destination) => (
          <MenuItem
            key={destination.id}
            component={
              <Link
                to={{
                  pathname: ROUTES['Destination'].getPath?.(
                    destination.slug,
                    destination.id
                  ),
                }}
                state={{ name: destination.name }}
              />
            }
            active={location.pathname.includes(destination.slug)}
          >
            {destination.name}
          </MenuItem>
        ))}
      </SubMenu>
    </SidebarComponent>
  );
}
export default Sidebar;
