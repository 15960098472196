import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';

import PaymentBreakdowns from '../../components/payment-breakdowns/payment-breakdowns';
import SalesPerformanceBreakdown from '../../components/sales-performance-breakdown/sales-performance-breakdown';
import UnitSalesRevenue from '../../components/unit-sales-revenue/unit-sales-revenue';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import useClearSelections from '../../hooks/use-clear-filters-selections';
import ReferralsOverview from '../../components/referrals-overview/referrals-overview';

export function SalesPerformance() {
  const { t } = useTranslation();

  const {
    filterDestinations,
    filterUnitTypes,
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    unitFilterLoader,
    destinationFilterLoader,
  } = useContext(FILTER_CONTEXT);

  const { selectedDuration } = useAnalyticsPeriodFilter();

  useClearSelections({
    handleSelectedDestinations,
    handleSelectedUnitTypes,
  });

  const { startDateArg, endDateArg } = useAnalyticsFilters();
  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {t('performance')}
          <b>
            {' / '} {t('salesPerformance')}
          </b>
        </h1>
      </div>

      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
          destinationsLoader={destinationFilterLoader}
          unitsLoader={unitFilterLoader}
        />
        <SalesPerformanceBreakdown
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <UnitSalesRevenue
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />

        <PaymentBreakdowns
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
        />
        {/* <UnitsResaleOverview /> */}
        {/* <ResaleRequestsVsSales /> */}
        <ReferralsOverview
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
      </div>
    </div>
  );
}

export default SalesPerformance;
