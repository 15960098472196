import { useTranslation } from 'react-i18next';
import {
  AnalyticsPeriodFilter,
  useAnalyticsPeriodFilter,
  useAnalyticsFilters,
} from '@orascom/broker-sales-man-common-components';
import DestinationPerformance from '../../components/destination-performance/destination-performance';
import UnitTypePerformance from '../../components/unit-type-performance/unit-type-performance';
import Summary from '../../components/summary/summary';
import { useContext } from 'react';
import disclaimer from '../../assets/icons/disclaimer.svg';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import useClearSelections from '../../hooks/use-clear-filters-selections';

export function Overview() {
  const { t } = useTranslation();
  const {
    filterDestinations,
    filterUnitTypes,
    selectedDestinations,
    handleSelectedDestinations,
    selectedDestinationsIds,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    unitFilterLoader,
    destinationFilterLoader,
  } = useContext(FILTER_CONTEXT);

  const { selectedDuration } = useAnalyticsPeriodFilter();

  const { startDateArg, endDateArg } = useAnalyticsFilters();

  useClearSelections({
    handleSelectedDestinations,
    handleSelectedUnitTypes,
  });
  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>{t('overview')}</h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          key={selectedDuration}
          filterDestinations={filterDestinations}
          filterUnitTypes={filterUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          portal="shopper-analytics"
          destinationsLoader={destinationFilterLoader}
          unitsLoader={unitFilterLoader}
        />
        {(selectedDestinationsIds.length > 0 ||
          selectedUnitTypesIds.length > 0) && (
          <div className="shopper-analytics__content__disclaimer">
            {' '}
            <img src={disclaimer} alt="" role="presentation" />{' '}
            {t('filterDisclaimer')}
          </div>
        )}
        <Summary
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
        />
        <DestinationPerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <UnitTypePerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedDestinations={selectedDestinations}
          selectedUnitTypes={selectedUnitTypes}
          selectedDestinationsIds={selectedDestinationsIds}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
      </div>
    </div>
  );
}

export default Overview;
