import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Engagement as EngagementUtils } from '../../utils/engagement';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';
import { GraphData, GraphProps } from '../../definitions/analytics';
import BarChart from '../bar-chart/bar-chart';
import { MostViewsBreakdown as MostViewsBreakdownType } from '../../definitions/interfaces/engagmenet';

export function MostViewsBreakdown(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [viewsBreakdown, setViewsBreakdown] =
    useState<MostViewsBreakdownType>();
  const [loading, setLoading] = useState(false);

  const activeTabs = [
    'Most Viewed Countries',
    'Most Viewed Destinations',
    'Most Brochure Downloads',
    'Most Viewed Pages',
  ];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [activeTabLabels, setActiveTabLabels] = useState<string[]>([]);
  const [activeTabData, setActiveTabData] = useState<GraphData[]>([]);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  const processTabData = (tab: string, views: MostViewsBreakdownType) => {
    let labels: string[] = [];
    let data: GraphData[] = [];

    switch (tab) {
      case 'Most Viewed Countries':
        labels = views?.countries_list?.map((country) => country.name);
        data = views?.countries_list?.map((country) => ({
          x: country.name,
          y: Number(country.views_count),
          currency: 'Views',
        }));
        break;
      case 'Most Viewed Destinations':
        labels =
          views?.destinations_list?.map((destination) => destination.name) ??
          [];
        data =
          views?.destinations_list?.map((destination) => ({
            x: destination.name,
            y: Number(destination.views_count),
            currency: 'Views',
          })) ?? [];
        break;
      case 'Most Brochure Downloads':
        labels =
          views?.download_brochure_list?.map((brochure) => brochure.name) ?? [];
        data =
          views?.download_brochure_list?.map((brochure) => ({
            x: brochure.name,
            y: Number(brochure.clicks_count),
            currency: 'Downloads',
          })) ?? [];
        break;
      case 'Most Viewed Pages':
        labels = views?.pages_list?.map((page) => page.name);
        data = views?.pages_list?.map((page) => ({
          x: page.name,
          y: Number(page.views_count),
          currency: 'Views',
        }));
        break;
      default:
        break;
    }

    return { labels, data };
  };

  useEffect(() => {
    if (activeTab && viewsBreakdown) {
      const { labels, data } = processTabData(activeTab, viewsBreakdown);
      setActiveTabLabels(labels);
      setActiveTabData(data);
    }
  }, [activeTab, viewsBreakdown]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    EngagementUtils.getAnalyticsMostViewsBreakdown(params)
      .then((res) => {
        setViewsBreakdown(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  const data = {
    labels: activeTabLabels,
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data: activeTabData || [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <BarChart
      description={t('mostViewsBreakdownDescription')}
      title={t('mostViewsBreakdown')}
      unMaintainAspectRatio
      activeTab={activeTab}
      activeTabs={activeTabs}
      setActiveTab={setActiveTab}
      data={data}
    />
  );
}

export default MostViewsBreakdown;
