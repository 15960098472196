import totalUnitsSoldIcon from '../../assets/icons/total-units-sold.svg';
import totalUnitsRevenueIcon from '../../assets/icons/units-revenue.svg';
import ReferralCard from '../referral-card/referral-card';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { RequestTypeEnum } from '../../definitions/interfaces/performance';
import { Loader } from '@orascom/common-components';

export interface ReferralsOverviewProps {
  startDateArg: string;
  endDateArg: string;
}
export function ReferralsOverview(props: Readonly<ReferralsOverviewProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [referrals, setReferrals] = useState<number>();
  const [resales, setResales] = useState<number>();
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');
  useEffect(() => {
    const paramsReferalls = {
      from: props.startDateArg,
      to: props.endDateArg,
      request_type: RequestTypeEnum.REFERRAL,
    };
    const paramsResales = {
      from: props.startDateArg,
      to: props.endDateArg,
      request_type: RequestTypeEnum.UNIT_RESALE,
    };

    Promise.all([
      PerformanceUtils.getAnalyticsUserRequests(paramsReferalls),
      PerformanceUtils.getAnalyticsUserRequests(paramsResales),
    ])
      .then((res) => {
        setReferrals(res[0]);
        setResales(res[1]);
      })
      .catch((err) => errorMessagesHandler(err.data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <h3 className="shoppes-analytics__sub-title">{t('referralsSubTitle')}</h3>
      <h2 className="summaryTitle">{t('referralsTitle')}</h2>

      <div className="cards__wrapper">
        <ReferralCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('totalResaleRequests')}
          cardDesc={resales?.toString() ?? ''}
        />
        <ReferralCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('totalReferrals')}
          cardDesc={referrals?.toString() ?? ''}
        />
      </div>
    </div>
  );
}

export default ReferralsOverview;
