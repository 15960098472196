import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import {
  GraphProps,
  UnitSalesRevenue as UnitSalesRevenueType,
} from '../../definitions/analytics';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export function UnitSalesRevenue(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const activeTabs = ['Destination', 'Unit Type'];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [unitSalesRevenue, setUnitSalesRevenue] =
    useState<UnitSalesRevenueType>();
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    PerformanceUtils.getAnalyticsUnitSalesRevenue(params)
      .then((res) => {
        setUnitSalesRevenue(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  const data = {
    labels:
      activeTab === 'Destination'
        ? unitSalesRevenue?.destinations_revenues.map(
            (destination) => destination.name
          )
        : unitSalesRevenue?.unit_types_revenues.map((unit) => unit.name),
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data:
          activeTab === 'Destination'
            ? unitSalesRevenue?.destinations_revenues?.map((destination) => ({
                x: destination.name,
                y: Number(destination.sales_revenues),
                currency: destination.currency,
              })) ?? []
            : unitSalesRevenue?.unit_types_revenues?.map((unit) => ({
                x: unit.name,
                y: Number(unit.sales_revenues),
                currency: unit.currency,
              })) ?? [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      description={t('unitSalesRevenueDescription')}
      title={t('unitSalesRevenue')}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default UnitSalesRevenue;
