import { FilterParams } from '@orascom/api-interfaces';
import { Services as ServicesApi } from '../api/services';
import { parseError } from '@orascom/utils';
import { UnitServicesRequestsByType } from '../definitions/interfaces/services';

export class Services {
  public static getUnitServicesRequestsByType(
    params: FilterParams
  ): Promise<UnitServicesRequestsByType[]> {
    return ServicesApi.getUnitServicesRequestsByType(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
