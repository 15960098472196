import { Network } from './network';
import {
  Destination,
  FilterParams,
  FilterValue,
  Payload,
} from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import { constructURL } from '@orascom/utils';
import {
  DestinationViews,
  OverviewSummary,
  PerformanceGraphs,
} from '../definitions/analytics';

export class Analytics {
  public static listDestinations(): Promise<Payload<Destination[]>> {
    return Network.fetch(
      `${process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT']}${ENDPOINTS.listDestinations.url}`,
      {
        method: ENDPOINTS.listDestinations.method,
      }
    );
  }

  public static getAnalyticsUnitPerformance(
    params: FilterParams
  ): Promise<Payload<PerformanceGraphs>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsUnitPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsUnitPerformance.method,
    });
  }

  public static getAnalyticsDestinationPerformance(
    params: FilterParams
  ): Promise<Payload<PerformanceGraphs>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsDestinationPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsDestinationPerformance.method,
    });
  }

  public static getAnalyticsDestinationViews(
    params: FilterParams
  ): Promise<Payload<DestinationViews>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsDestinationViews.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsDestinationViews.method,
    });
  }

  public static getAnalyticsOverviewSummary(
    params: FilterParams
  ): Promise<Payload<OverviewSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewSummary.method,
    });
  }

  public static getAnalyticsFilterDestinations(
    params: FilterParams
  ): Promise<Payload<FilterValue[]>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsFilterDestinations.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsFilterDestinations.method,
    });
  }

  public static getAnalyticsFilterUnitTypes(
    params: FilterParams
  ): Promise<Payload<FilterValue[]>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsFilterUnitTypes.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsFilterUnitTypes.method,
    });
  }
}
