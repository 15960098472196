import { FilterParams, Payload } from '@orascom/api-interfaces';
import { ENDPOINTS } from './endpoints';
import {
  CurrencyBreakdown,
  PaymentBreakdown,
} from '../definitions/interfaces/performance';
import { constructURL } from '@orascom/utils';
import { Network } from './network';
import {
  LeadPerformance,
  SalesPerformanceBreakdown,
} from '@orascom/broker-sales-man-common-components';
import {
  DirectVsIndirectSales,
  LeadsPerDestination,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
  SalesFunnelAnalysis,
  SalesVsLeads,
  UnitSalesRevenue,
} from '../definitions/analytics';

export class Performance {
  public static getSalesPerformancePaymentBreakdown(
    params: FilterParams
  ): Promise<Payload<PaymentBreakdown>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getSalesPerformancePaymentBreakdown.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSalesPerformancePaymentBreakdown.method,
    });
  }

  public static getSalesPerformanceCurrencyBreakdown(
    params: FilterParams
  ): Promise<Payload<CurrencyBreakdown>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getSalesPerformanceCurrencyBreakdown.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSalesPerformanceCurrencyBreakdown.method,
    });
  }

  public static getAnalyticsLeadsPerformance(
    params: FilterParams
  ): Promise<Payload<LeadPerformance>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsLeadsPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsLeadsPerformance.method,
    });
  }

  public static getAnalyticsOverviewUserInterestSummary(
    params: FilterParams
  ): Promise<Payload<OverviewUserInterestSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewUserInterestSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewUserInterestSummary.method,
    });
  }

  public static getAnalyticsLeadsPerDestination(
    params: FilterParams
  ): Promise<Payload<LeadsPerDestination>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsLeadsPerDestination.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsLeadsPerDestination.method,
    });
  }

  public static getSalesCountPerDestination(
    params: FilterParams
  ): Promise<Payload<SalesVsLeads>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getSalesCountPerDestination.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getSalesCountPerDestination.method,
    });
  }

  public static getAnalyticsSalesPerformance(
    params: FilterParams
  ): Promise<Payload<SalesPerformanceBreakdown>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesPerformance.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesPerformance.method,
    });
  }

  public static getAnalyticsOverviewUserPurchaseSummary(
    params: FilterParams
  ): Promise<Payload<OverviewUserPurchaseSummary>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsOverviewUserPurchaseSummary.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsOverviewUserPurchaseSummary.method,
    });
  }

  public static getAnalyticsUnitSalesRevenue(
    params: FilterParams
  ): Promise<Payload<UnitSalesRevenue>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsUnitSalesRevenue.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsUnitSalesRevenue.method,
    });
  }

  public static getAnalyticsDirectVsIndirectSales(
    params: FilterParams
  ): Promise<Payload<DirectVsIndirectSales>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsDirectVsIndirectSales.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsDirectVsIndirectSales.method,
    });
  }

  public static getAnalyticsUserRequests(
    params: FilterParams
  ): Promise<Payload<number>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsUserRequests.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsUserRequests.method,
    });
  }

  public static getAnalyticsSalesFunnel(
    params: FilterParams
  ): Promise<Payload<SalesFunnelAnalysis>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsSalesFunnel.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsSalesFunnel.method,
    });
  }
}
