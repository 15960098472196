import styles from './units-services-requests-by-type.module.scss';
import { useEffect, useState } from 'react';
import { Services as ServicesUtils } from '../../utils/services';
import { errorMessagesHandler } from '@orascom/utils';
import { UnitServicesRequestsByType } from '../../definitions/interfaces/services';
import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { Loader } from '@orascom/common-components';
import { GraphProps } from '../../definitions/analytics';
import { useSearchParams } from 'react-router-dom';

export function UnitsServicesRequestsByType(props: Readonly<GraphProps>) {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  const [unitsServicesRequestsByType, setUnitsServicesRequestsByType] =
    useState<UnitServicesRequestsByType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    ServicesUtils.getUnitServicesRequestsByType(params)
      .then((res) => {
        setUnitsServicesRequestsByType(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  if (loading) {
    return <Loader />;
  }

  const chartData = {
    labels: unitsServicesRequestsByType.map((u) =>
      u.service_type.replace(/_/g, ' ')
    ),
    datasets: [
      {
        label: t('countOfRequests'),
        data: unitsServicesRequestsByType.map((u) => ({
          x: u.service_type.replace(/_/g, ' '),
          y: u.requests_count,
        })),
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  const totalRequests = unitsServicesRequestsByType.reduce(
    (sum, item) => sum + item.requests_count,
    0
  );
  return (
    <div className={styles['wrapper']}>
      <BarChart
        description={t('unitServicesDesc')}
        title={t('unitServicesTitle')}
        data={chartData}
        chartTitle={t('TotalNoOfRequests')}
        chartTitleValue={totalRequests.toString()}
        unMaintainAspectRatio
      />
    </div>
  );
}

export default UnitsServicesRequestsByType;
