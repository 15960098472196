import { FilterParams, Payload } from '@orascom/api-interfaces';
import { LoginsOverTime } from '@orascom/broker-sales-man-common-components';
import { constructURL } from '@orascom/utils';
import { ENDPOINTS } from './endpoints';
import { Network } from './network';
import {
  MostViewsBreakdown,
  PageViewsPerCountry,
} from '../definitions/interfaces/engagmenet';

export class Engagement {
  public static getAnalyticsLoginsOverTime(
    params: FilterParams
  ): Promise<Payload<LoginsOverTime>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsLoginsOverTime.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsLoginsOverTime.method,
    });
  }

  public static getAnalyticsMostViewsBreakdown(
    params: FilterParams
  ): Promise<Payload<MostViewsBreakdown>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsMostViewsBreakdown.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsMostViewsBreakdown.method,
    });
  }

  public static getAnalyticsPageViewsPerCountry(
    params: FilterParams
  ): Promise<Payload<PageViewsPerCountry[]>> {
    const fetchURL = constructURL(
      process.env['NX__SHOPPER_ANALYTICS_API_ENDPOINT'],
      ENDPOINTS.getAnalyticsPageViewsPerCountry.url,
      params
    );
    return Network.fetch(fetchURL, {
      method: ENDPOINTS.getAnalyticsPageViewsPerCountry.method,
    });
  }
}
