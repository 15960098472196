import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';
import { useEffect, useState } from 'react';
import { OptionValue, PeriodType } from '@orascom/api-interfaces';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { LeadPerformance as LeadPerformanceType } from '@orascom/broker-sales-man-common-components';
import {
  GraphProps,
  OverviewUserInterestSummary,
} from '../../definitions/analytics';
import Numeral from 'numeral';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export interface LeadsPerformanceProps {
  startDateArg: string;
  endDateArg: string;
  selectedDestinations: OptionValue<string | number>[];
  selectedDestinationsIds: string[];
  selectedDuration: PeriodType;
}
export function LeadsPerformance(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [leadPerformance, setLeadPerformance] = useState<LeadPerformanceType>();
  const [
    analyticsOverviewUserInterestSummary,
    setAnalyticsOverviewUserInterestSummary,
  ] = useState<OverviewUserInterestSummary>();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
    };
    Promise.all([
      PerformanceUtils.getAnalyticsLeadsPerformance(params),
      PerformanceUtils.getAnalyticsOverviewUserInterestSummary(params),
    ])
      .then((res) => {
        setLeadPerformance(res[0]);
        setAnalyticsOverviewUserInterestSummary(res[1]);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedDestinations]);

  const metricData = [
    {
      label: t('talkToSalesSubmission'),
      value: leadPerformance?.leads_count.toString(),
    },
    {
      label: t('intresetConversionRate'),
      value:
        Numeral(
          analyticsOverviewUserInterestSummary?.interest_conversion_rate
        ).format('0,0.000') + '%',
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <LineChart
      description={t('leadPerformanceDescription')}
      title={t('leadsPerformance')}
      res={leadPerformance ? [leadPerformance] : []}
      selectedDuration={props.selectedDuration ?? 'today'}
      unMaintainAspectRatio
      metricsData={metricData}
      tooltipTitle="Leads"
    />
  );
}

export default LeadsPerformance;
