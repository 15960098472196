import { FilterParams } from '@orascom/api-interfaces';
import { LoginsOverTime } from '@orascom/broker-sales-man-common-components';
import { Engagement as EngagementApi } from '../api/engagement';
import { parseError } from '@orascom/utils';
import {
  MostViewsBreakdown,
  PageViewsPerCountry,
} from '../definitions/interfaces/engagmenet';

export class Engagement {
  public static getAnalyticsLoginsOverTime(
    params: FilterParams
  ): Promise<LoginsOverTime> {
    return EngagementApi.getAnalyticsLoginsOverTime(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsMostViewsBreakdown(
    params: FilterParams
  ): Promise<MostViewsBreakdown> {
    return EngagementApi.getAnalyticsMostViewsBreakdown(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getAnalyticsPageViewsPerCountry(
    params: FilterParams
  ): Promise<PageViewsPerCountry[]> {
    return EngagementApi.getAnalyticsPageViewsPerCountry(params)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return Promise.reject(parseError(error));
      });
  }
}
