import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Performance as PerformanceUtils } from '../../utils/performace';
import {
  GraphProps,
  LeadsPerDestination as LeadsPerDestinationType,
} from '../../definitions/analytics';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';

export function LeadsPerDestination(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [leadsPerDestination, setLeadsPerDestination] =
    useState<LeadsPerDestinationType>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
    };
    PerformanceUtils.getAnalyticsLeadsPerDestination(params)
      .then((res) => {
        setLeadsPerDestination(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    searchParams.get('start'),
    searchParams.get('end'),
    props.selectedDestinations,
  ]);

  const data = {
    labels:
      leadsPerDestination?.requests.map((destination) => destination.name) ??
      [],
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data:
          leadsPerDestination?.requests.map((destination) => ({
            x: destination.name,
            y: Number(destination.requests_count),
          })) ?? [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <BarChart
      description={t('leadsPerDestinationDescription')}
      title={t('leadsPerDestination')}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default LeadsPerDestination;
