import { FilterValue, OptionValue } from '@orascom/api-interfaces';
import { createContext, Context, SetStateAction, Dispatch } from 'react';

interface FilterContextInterface {
  filterDestinations?: FilterValue[];
  filterUnitTypes?: FilterValue[];
  setFilterDestinations?: Dispatch<SetStateAction<FilterValue[]>>;
  setFilterUnitTypes?: Dispatch<SetStateAction<FilterValue[]>>;
  selectedUnitTypesIds: string[];
  selectedDestinationsIds: string[];
  handleSelectedUnitTypes?: (value: OptionValue[]) => void;
  handleSelectedDestinations?: (value: OptionValue[]) => void;
  selectedUnitTypes: OptionValue<string | number>[];
  selectedDestinations: OptionValue<string | number>[];
  unitFilterLoader?: boolean;
  destinationFilterLoader?: boolean;
}

export const FILTER_CONTEXT: Context<FilterContextInterface> =
  createContext<FilterContextInterface>({
    selectedUnitTypesIds: [],
    selectedDestinationsIds: [],
    selectedUnitTypes: [],
    selectedDestinations: [],
  });
