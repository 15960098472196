import { useEffect } from 'react';
import { OptionValue } from '@orascom/api-interfaces';

interface UseClearSelectionsProps {
  destinationSlug?: string;
  handleSelectedDestinations?: (value: OptionValue[]) => void;
  handleSelectedUnitTypes?: (value: OptionValue[]) => void;
}
function useClearSelections(props: UseClearSelectionsProps) {
  useEffect(() => {
    if (props.handleSelectedDestinations) {
      props.handleSelectedDestinations([]);
    }
    if (props.handleSelectedUnitTypes) {
      props.handleSelectedUnitTypes([]);
    }
  }, [props.destinationSlug]);
}

export default useClearSelections;
