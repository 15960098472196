/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import Numeral from 'numeral';
import styles from './summary.module.scss';
import totalUnitsSoldIcon from '../../assets/icons/total-units-sold.svg';
import totalUnitsRevenueIcon from '../../assets/icons/units-revenue.svg';
import totalRequestsIcon from '../../assets/icons/total-requests.svg';
import conversionRateIcon from '../../assets/icons/conversion-rate.svg';
import SummaryCard from '../summary-card/summary-card';
import { Analytics as AnalyticsUtils } from '../../utils/analytics';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { useEffect, useState } from 'react';
import {
  GraphProps,
  OverviewSummary,
  OverviewUserInterestSummary,
  OverviewUserPurchaseSummary,
} from '../../definitions/analytics';
import { Loader } from '@orascom/common-components';
import { errorMessagesHandler } from '@orascom/utils';
import { useSearchParams } from 'react-router-dom';

export function Summary(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [interestLoading, setInterestLoading] = useState(true);
  const [analyticsOverviewSummary, setAnalyticsOverviewSummary] =
    useState<OverviewSummary>();
  const [
    analyticsOverviewUserPurchaseSummary,
    setAnalyticsOverviewUserPurchaseSummary,
  ] = useState<OverviewUserPurchaseSummary>();
  const [
    analyticsOverviewUserInterestSummary,
    setAnalyticsOverviewUserInterestSummary,
  ] = useState<OverviewUserInterestSummary>();
  const [searchParams] = useSearchParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    PerformanceUtils.getAnalyticsOverviewUserInterestSummary(params)
      .then((res) => {
        setAnalyticsOverviewUserInterestSummary(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setInterestLoading(false);
      });
  }, [startTimeStamp, endTimeStamp]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    Promise.all([
      AnalyticsUtils.getAnalyticsOverviewSummary(params),
      PerformanceUtils.getAnalyticsOverviewUserPurchaseSummary(params),
    ])
      .then((res) => {
        setAnalyticsOverviewSummary(res[0]);
        setAnalyticsOverviewUserPurchaseSummary(res[1]);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    startTimeStamp,
    endTimeStamp,
    props.selectedDestinations,
    props.selectedUnitTypes,
  ]);

  if (loading || interestLoading) {
    return <Loader />;
  }

  const unitsRevenueInfo = analyticsOverviewSummary?.total_units_revenues;

  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">{t('summarySubtitle')}</h3>
      <h2 className="shoppes-analytics__title">{t('summaryTitle')}</h2>
      <div className="cards__wrapper">
        <SummaryCard
          cardIconSrc={totalUnitsSoldIcon}
          cardTitle={t('totalSoldUnits')}
          cardDesc={`${Numeral(
            analyticsOverviewSummary?.total_sold_units
          ).format('0,0')} Units`}
        />
        <SummaryCard
          cardIconSrc={totalUnitsRevenueIcon}
          cardTitle={t('totalUnitsRevenue')}
          cardDesc={`${Numeral(unitsRevenueInfo?.sales_revenues).format(
            '0,0'
          )} ${unitsRevenueInfo?.currency}`}
        />
        <SummaryCard
          dim={
            props.selectedUnitTypesIds?.length
              ? props.selectedUnitTypesIds.length > 0
              : false
          }
          cardIconSrc={totalRequestsIcon}
          cardTitle={t('totalRequests')}
          cardDesc={Numeral(analyticsOverviewSummary?.total_requests).format(
            '0,0'
          )}
        />
        <SummaryCard
          cardIconSrc={conversionRateIcon}
          cardTitle={t('visitorToPurchaseConversionRate')}
          cardDesc={`${Numeral(
            analyticsOverviewUserPurchaseSummary?.users_purchase_rate
          ).format('0,0.000')}%`}
        />
        <SummaryCard
          dim={
            (props.selectedDestinationsIds?.length || 0) > 0 ||
            (props.selectedUnitTypesIds?.length || 0) > 0
          }
          cardIconSrc={conversionRateIcon}
          cardTitle={t('visitorToInterestConversionRate')}
          cardDesc={`${Numeral(
            analyticsOverviewUserInterestSummary?.interest_conversion_rate
          ).format('0,0.000')}%`}
        />
      </div>
    </div>
  );
}

export default Summary;
