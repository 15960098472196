import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Performance as PerformanceUtils } from '../../utils/performace';
import { errorMessagesHandler } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import {
  GraphProps,
  SalesVsLeads as SalesVsLeadsType,
} from '../../definitions/analytics';
import BarChart from '../bar-chart/bar-chart';
import { useSearchParams } from 'react-router-dom';

export function SalesVsLeads(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [salesVsLeads, setSalesVsLeads] = useState<SalesVsLeadsType>();
  const activeTabs = ['Destination'];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds,
    };
    PerformanceUtils.getSalesCountPerDestination(params)
      .then((res) => {
        setSalesVsLeads(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedDestinations]);

  const data = {
    labels: salesVsLeads?.destinations.map((destination) => destination.name),
    datasets: [
      {
        label: t('salesCount'),
        data:
          salesVsLeads?.destinations.map((destination) => ({
            x: destination.name,
            y: destination.sales_count,
          })) ?? [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
      {
        label: t('leadsCount'),
        data:
          salesVsLeads?.destinations.map((destination) => ({
            x: destination.name,
            y: destination.requests_count,
          })) ?? [],
        backgroundColor: '#B4905C',
        hoverBackgroundColor: '##B4905C',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      title={t('salesVsLeads')}
      description={t('salesVsLeadsDescription')}
      data={data}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default SalesVsLeads;
