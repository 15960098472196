import { useTranslation } from 'react-i18next';
import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import CustomerDemographics from '../../components/customer-demographics/customer-demographics';

export function Demographics() {
  const { t } = useTranslation();
  const { selectedDuration } = useAnalyticsPeriodFilter();

  const { startDateArg, endDateArg } = useAnalyticsFilters();

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {' '}
          {t('engagement')}
          <b>
            {' / '} {t('demographics')}
          </b>
        </h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter portal="shopper-analytics" />
        <CustomerDemographics
          selectedDuration={selectedDuration}
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
      </div>
    </div>
  );
}

export default Demographics;
