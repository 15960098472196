import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useEffect, useState } from 'react';
import { Destination as DestinationUtils } from '../../utils/destination';
import { errorMessagesHandler, getButtonText } from '@orascom/utils';
import {
  GraphProps,
  SalesRevenuePerUnitType,
} from '../../definitions/analytics';
import { Loader } from '@orascom/common-components';
import { useSearchParams, useParams } from 'react-router-dom';

export function SalesRevenueByUnitType(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [unitSalesRevenue, setUnitSalesRevenue] =
    useState<SalesRevenuePerUnitType>();
  const [searchParams] = useSearchParams();
  const { destinationId } = useParams();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'unit_type_ids[]': props.selectedUnitTypesIds,
      'destination_ids[]': [destinationId ?? ''],
    };
    DestinationUtils.getAnalyticsSalesRevenuePerUnitType(params)
      .then((res) => {
        setUnitSalesRevenue(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedUnitTypes]);

  const data = {
    labels: unitSalesRevenue?.unit_types_revenues.map((unit) => unit.name),
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data:
          unitSalesRevenue?.unit_types_revenues?.map((unit) => ({
            x: unit.name,
            y: Number(unit.sales_revenues),
            currency: unit.currency,
          })) ?? [],
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      description={t('salesRevenueByUnitTypeDescription')}
      title={t('salesRevenueByUnitType')}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default SalesRevenueByUnitType;
