import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FILTER_CONTEXT } from '../../contexts/filters-context';
import useClearSelections from '../../hooks/use-clear-filters-selections';
import DestinationSalesPerformance from '../../components/destination-sales-performance/destination-sales-performance';
import PaymentBreakdowns from '../../components/payment-breakdowns/payment-breakdowns';
import SalesRevenueByUnitType from '../../components/sales-revenue-by-unit-type/sales-revenue-by-unit-type';

export function Destination() {
  const { t } = useTranslation();
  const {
    filterUnitTypes,
    selectedUnitTypes,
    handleSelectedUnitTypes,
    selectedUnitTypesIds,
    unitFilterLoader,
    handleSelectedDestinations,
  } = useContext(FILTER_CONTEXT);

  const { startDateArg, endDateArg } = useAnalyticsFilters();
  const { destinationSlug } = useParams();
  const { selectedDuration, handleSetSelectedDuration } =
    useAnalyticsPeriodFilter();

  useClearSelections({
    destinationSlug,
    handleSelectedDestinations,
    handleSelectedUnitTypes,
  });

  const location = useLocation();
  const destinationName = location.state?.name ?? destinationSlug;

  useEffect(() => {
    handleSetSelectedDuration('lastyear');
  }, [destinationSlug]);
  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {' '}
          {t('destination')}
          <b>
            {' / '} {destinationName}
          </b>
        </h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter
          key={destinationSlug}
          filterUnitTypes={filterUnitTypes}
          portal="shopper-analytics"
          unitsLoader={unitFilterLoader}
          handleSelectedUnitTypes={handleSelectedUnitTypes}
          handleSelectedDestinations={handleSelectedDestinations}
        />
        <DestinationSalesPerformance
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedUnitTypes={selectedUnitTypes}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <SalesRevenueByUnitType
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedUnitTypes={selectedUnitTypes}
          selectedUnitTypesIds={selectedUnitTypesIds}
          selectedDuration={selectedDuration}
        />
        <PaymentBreakdowns
          startDateArg={startDateArg}
          endDateArg={endDateArg}
          selectedUnitTypes={selectedUnitTypes}
          selectedUnitTypesIds={selectedUnitTypesIds}
        />
      </div>
    </div>
  );
}

export default Destination;
