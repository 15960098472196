import { useTranslation } from 'react-i18next';
import LineChart from '../line-chart/line-chart';
import { useEffect, useState } from 'react';
import { errorMessagesHandler } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useParams, useSearchParams } from 'react-router-dom';
import { GraphProps } from '../../definitions/analytics';
import { Destination as DestinationUtils } from '../../utils/destination';
import { CountPerUnitTypeBreakdown } from '../../definitions/interfaces/destinations';

export function DestinationSalesPerformance(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [destinationSalesPerformance, setDestinationSalesPerformance] =
    useState<CountPerUnitTypeBreakdown>();

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  const { destinationId } = useParams();

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
      'destination_ids[]': props.selectedDestinationsIds ?? [
        destinationId ?? '',
      ],
      'unit_type_ids[]': props.selectedUnitTypesIds,
    };
    DestinationUtils.getAnalyticsDestinationSalesPerformance(params)
      .then((res) => {
        setDestinationSalesPerformance(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp, props.selectedUnitTypes]);

  const salesMetricsData = [
    {
      label: t('totalSoldUnits'),
      value: destinationSalesPerformance?.total_sold_units + ' Units',
    },
    {
      label: t('totalUnitsRevenue'),
      value:
        (destinationSalesPerformance?.total_units_revenues.sales_revenues ??
          '') +
        ' ' +
        (destinationSalesPerformance?.total_units_revenues.currency ?? ''),
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <LineChart
      description={t('salesPerformanceDestinationDescription')}
      title={t('salesPerformance')}
      res={destinationSalesPerformance?.unit_types_breakdown}
      selectedDuration={props.selectedDuration ?? 'today'}
      metricsData={salesMetricsData}
      unMaintainAspectRatio
      tooltipTitle="Units"
    />
  );
}

export default DestinationSalesPerformance;
